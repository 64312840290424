module.exports={
    HELP:{
        RPM:`Una Unidad de Mantenimiento está comprendida por un conjunto de equipamiento 
        agrupado para cumplir una funcion especifica dentro una estación transformadora.`,
        RSP:"El campo {PATH} no tiene el largo requerido",
        MUA:"El campo {PATH} no tiene el largo requerido",
        DIST:" ",
        MCP:"",
        PINM:"",
        PINT:"",
    }

}